
import Vue from 'vue'

export default class {

    getRouteQuery(routerName) {
        return Vue.$cookies.get(`${routerName}_Query`)

    }

    setRouteQuery(routerName, value) {
        Vue.$cookies.set(`${routerName}_Query`, value, 60 * 60 * 1); // Expire 1 小時
    }

}