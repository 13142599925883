<!-- 顯示一排資料 -->
<template>
    <div>
        <div v-if="dataHeaders" style="width: 80%; margin: 0 auto; padding-bottom: 15px">
            <span v-for="d in dataHeaders" v-bind:key="d.key">
                <span v-if="d.label" style="padding-right: 10px">{{d.label}}:</span> <span style="padding-right: 20px">{{getHeaderData(d.key)}}</span>
            </span>
        </div>
    </div>
</template>

<script>
    import i18n from '@/i18n'
    import ApiService from '@/ApiService';

    export default {
        data() {
            return {
                qryModel: {},           // bind 到 query string 的 object
                headerRecord: {}        // 資料的 headers，要顯示在上方
            };
        },
        props: {
            name: String,               // 資料庫 table 的名稱，也是 Controller 的名字
            pkColumn: String,           // 資料庫 table PK 的名稱
            dataHeaders: Array,         // 要顯示在 table 的欄位
        },
        async created() {

            //  Show parent 的資料在上面的 header
            this.getAll();
        },
        methods: {
            /*
             *  從後台讀取要顯示的資料
             */
            async getAll() {
                this.qryModel = JSON.parse(JSON.stringify(this.$route.query))

                // Language
                this.qryModel["Language"] = i18n.locale;

                if (!this.name || !this.pkColumn || !this.dataHeaders)
                    return;

                const parentApi = new ApiService(this.name);
                this.headerRecord = await parentApi.get(this.qryModel[this.pkColumn], i18n.locale);
            },
            /*
             *  傳回 header 的資料
             *  @param {string} key header 的 key
             */
            getHeaderData(key) {
                if (key in this.headerRecord) {
                    return this.headerRecord[key];
                }

                return "";
            }
        },
        watch: {
            '$route.params': 'getAll'
        }
    }

</script>