<template>
    <cmpRec v-bind:name="name"
                   v-bind:pkColumn="pkColumn"
                   v-bind:fields="fields"
                   v-bind:parent="parent"
                   v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    { "key": "UserName", "label": i18n.t('component.ProjectParticipant.UserName.label') },
                    { "key": "ProjectRole", "label": i18n.t('component.ProjectParticipant.ProjectRoleName.label') },
                    { "key": "ClassCode", "label": i18n.t('component.ProjectParticipant.ClassCode.label') }
                ]
            },
            header() {
                return i18n.t('component.ProjectParticipant.recHeader')
            }
        },
        data() {

            return {
                name: "ProjectParticipant",
                pkColumn: "ProjectParticipantId",
                parent: {}
            };
        }
    }
</script>