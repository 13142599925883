<!-- 顯示單筆資料的 form，只能 readonly -->
<template>
    <div>
        <div id="webpath">{{header}}</div>
        <cmpLoading v-if="loading"></cmpLoading>

        <div style="text-align:center">
        <cmpDataHeaderRow v-if="parent && parent.dataHeaders"
                          v-bind:name="parent.name"
                          v-bind:pkColumn="parent.key"
                          v-bind:dataHeaders="parent.dataHeaders"></cmpDataHeaderRow>
            <hr />
        </div>
        <div>
            <table border="0" align="center" class="table2">
                <tr v-for="f in fields" :key="f.key">
                    <td align="left">
                        {{f.label}}
                    </td>
                    <td align="left">
                        <!--<textarea v-if="f.input && f.input.type == 'textarea'" v-model="model[f.key]" v-bind:style="f.input && f.input.style ? f.input.style : null"
                                  readonly />-->
                        <!--<input v-else type="text" v-model="model[f.key]" v-bind:style="f.input && f.input.style ? f.input.style : null" readonly />-->
                        <div v-bind:style="f.input && f.input.style ? f.input.style : null">{{model[f.key]}}</div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" align="center">
                        <br />
                        <div class="btn9" @click.prevent="onCancel">✘ {{ $t('form.goBack') }}</div>
                    </td>
                </tr>
            </table>

        </div>

    </div>
</template>

<script>
    import i18n from '@/i18n'
    import ApiService from '@/ApiService';
    import cmpLoading from "@/components/common/cmpLoading"
    import cmpDataHeaderRow from "@/components/common/cmpDataHeaderRow"
    import { gmtToLocal } from '@/util/dateutil';
    import cookieManager from '@/util/cookieManager';

    var api = undefined
    var cm = new cookieManager();

    export default {
        components: {
            cmpLoading, cmpDataHeaderRow
        },
        data() {
            return {
                loading: false, // 是不是正在從後台讀取資料
                model: {},      // bind 到這個 form 的 Object
                formBind: {}    // bind 到各別 input 的值，例如 combobox, checkbox, radio button
            };
        },
        props: {
            name: String,   // table 的名字, 也是 Controller 的名字
            fields: Array,  // 要顯示的欄位
            parent: Object, // 母畫面的 information
            header: String  // 顯示的標題
        },
        async created() {
            api = new ApiService(this.name)
            this.model.id = this.$route.query.id

            if (this.model.id) {
                await this.get(this.model.id)
            }
            else if (this.parent && this.parent.key in this.$route.query) {
                this.model[this.parent.key] = this.$route.query[this.parent.key]
            }

            // Format datetime
            for (let f of this.$props.fields) {
                if (f.input) {
                    if (f.input.type == 'datetime') {
                        let localDateString = gmtToLocal(this.model[f.key]);
                        localDateString = localDateString.replace('T', ' ');
                        this.$set(this.model, f.key, localDateString);
                    }
                }
            }

        },
        methods: {
            /*
             *  從後台讀取這筆資料
             *  @param {number} id 這筆資料的 id
             */
            async get(id) {
                this.loading = true
                
                try {
                    this.model = await api.get(id, i18n.locale)
                    this.model['id'] = id;
                } finally {
                    this.loading = false
                }
            },
            /*
             *  返回到前一頁
             */
            onCancel() {
                //this.$router.go(-1);

                var routerParam = this.getRouterParam();
                this.$router.push(routerParam);
            },
            /*
             *  建要返回到前一頁的 router param
             */
            getRouterParam() {
                var routerName = this.$root.$data.store.getRouteName(this.name, "lst");

                var routerParam = { name: routerName };
                routerParam['query'] = {};
                
                if (this.parent && Object.keys(this.parent).length > 0) {
                    routerParam['query'][this.parent.key] = this.$route.query[this.parent.key]
                }
                
                let cookieQuery = cm.getRouteQuery(routerName);
                if (cookieQuery) {
                    for (const key in cookieQuery) {
                        if (cookieQuery[key]) {
                            routerParam['query'][key] = cookieQuery[key];
                        }
                    }
                }

                return routerParam;
            },
        }
    }
</script>